import React from "react";

import { Layout } from "../components";

const EmptyPage = () => (
  <Layout>
    <h1>The big empty</h1>
  </Layout>
);

export default EmptyPage;
